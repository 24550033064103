import store from '../../store';
import Errors from '../../Errors';
import ProductRating from '../ProductRating.vue';
import ProductCardMixin from '../../mixins/ProductCardMixin';
import RelatedProducts from './show/RelatedProducts.vue';

export default {
    components: { ProductRating, RelatedProducts },

    mixins: [
        ProductCardMixin,
    ],

    props: ['product', 'countOfReviews', 'avgRating'],

    data() {
        return {
            price: this.product.formatted_price,
            currentTab: 'description',
            currentReviewPage: 1,
            loadingReviews: false,
            reviews: {},
            postingReview: false,
            customReviewForm: {},
            basketProductForm: {
                product_id: this.product.id,
                qty: 1,
                options: {},
            },
            errors: new Errors(),
        };
    },

    computed: {
        reviewsInTotal() {
            if (! this.reviews.total) {
                return this.countOfReviews;
            }

            return this.reviews.total;
        },

        percentValue() {
            return (this.avgRating / 5) * 100;
        },

        noReviews() {
            return this.reviewsInTotal === 0;
        },

        totalReviewsVal() {
            return Math.ceil(this.reviews.total / 4);
        },
    },

    created() {
        this.loadAllReviews();
    },

    mounted() {
        $(this.$refs.upsellProductList).slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: window.App.rtl,
        });
    },

    methods: {
        refreshQuantity(qty) {
            if (qty < 1 || this.stockExceed(qty)) {
                return;
            }

            if (isNaN(qty)) {
                qty = 1;
            }

            this.basketProductForm.qty = qty;
        },

        stockExceed(qty) {
            return this.product.manage_stock && this.product.qty < qty;
        },

        switchToReviews(page) {
            this.currentReviewPage = page;

            this.loadAllReviews();
        },

        refreshPrice() {
            this.$nextTick(() => {
                $.ajax({
                    method: 'POST',
                    url: route('products.price.show', { id: this.product.id }),
                    data: { options: this.basketProductForm.options },
                }).then((price) => {
                    this.price = price;
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                });
            });
        },

        refreshSelectTypeOptionValue(optionId, e) {
            this.$set(this.basketProductForm.options, optionId, $(e.target).val());

            this.errors.clear(`options.${optionId}`);
        },

        refreshCheckboxTypeOptionValue(optionId, e) {
            let values = $(e.target)
                .parents('.variant-check')
                .find('input[type="checkbox"]:checked')
                .map((_, el) => {
                    return el.value;
                });

            this.$set(this.basketProductForm.options, optionId, values.get());
        },

        cRadioOptionValueEnabled(optionId, valueId) {
            if (! this.basketProductForm.options.hasOwnProperty(optionId)) {
                return false;
            }

            return this.basketProductForm.options[optionId] === valueId;
        },

        updateCRadioOptionValue(optionId, valueId) {
            if (this.cRadioOptionValueEnabled(optionId, valueId)) {
                this.$delete(this.basketProductForm.options, optionId);
            } else {
                this.$set(this.basketProductForm.options, optionId, valueId);

                this.errors.clear(`options.${optionId}`);
            }

            this.refreshPrice();
        },

        cCheckboxOptionValueEnabled(optionId, valueId) {
            if (! this.basketProductForm.options.hasOwnProperty(optionId)) {
                this.$set(this.basketProductForm.options, optionId, []);

                return false;
            }

            return this.basketProductForm.options[optionId].includes(valueId);
        },

        updateCCheckboxOptionValue(optionId, valueId) {
            if (this.cCheckboxOptionValueEnabled(optionId, valueId)) {
                this.basketProductForm.options[optionId].splice(
                    this.basketProductForm.options[optionId].indexOf(valueId),
                    1
                );
            } else {
                this.basketProductForm.options[optionId].push(valueId);

                this.errors.clear(`options.${optionId}`);
            }

            this.refreshPrice();
        },

        loadAllReviews() {
            this.loadingReviews = true;

            $.ajax({
                method: 'GET',
                url: route('products.reviews.index', {
                    productId: this.product.id,
                    page: this.currentReviewPage,
                }),
            }).then((reviews) => {
                this.reviews = reviews;
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.loadingReviews = false;
            });
        },

        postNewReview() {
            this.postingReview = true;

            $.ajax({
                method: 'POST',
                url: route('products.reviews.store', { productId: this.product.id }),
                data: this.customReviewForm,
            }).then((review) => {
                this.customReviewForm = {};
                this.reviews.total++;
                this.reviews.data.unshift(review);

                $('.captcha-input').prev('img').trigger('click');
            }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                } else {
                    this.$notify(xhr.responseJSON.message);
                }
            }).always(() => {
                this.postingReview = false;
            });
        },

        addToBasket() {
            this.cartAddLoading = true;


            $.ajax({
                method: 'POST',
                url: route('cart.items.store', this.basketProductForm),
            }).then((cart) => {
                store.updateBasket(cart);
                $('.header-cart').trigger('click');

                UIkit.notification({
                    message: 'Produkt bol pridaný do košíka',
                    status: 'success',
                    pos: 'bottom-right',
                    timeout: 5000
                });
                $('.uk-drop').show();
            }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.$notify(xhr.responseJSON.message);
                    this.errors.record(xhr.responseJSON.errors);
                } else {
                    this.$notify(xhr.responseJSON.message);
                }
            }).always(() => {
                this.cartAddLoading = false;
            });
        },
    },
};
