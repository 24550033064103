<template>
  <div class="hello">
    <div class="box">
      <round-slider
        ref="rslider"
        v-model="sliderValue"
        min="5"
        max="1"
        step="0.1"
        startAngle="130"
        endAngle="50"
        radius="120"
        width="8"
        handleSize="24"
        startValue="1"
        rangeColor="#ff9500"
        pathColor="#c4c4c4"
        borderWidth="0"
        lineCap="round"
        :tooltipFormat="tooltipFormat"
        :create="createTicks"
        :value-change="updateTickColor"
        :change="save"
      ></round-slider>
    </div>

<!--    <h3>Text Element</h3>-->
<!--    <div>{{ sliderValue }}</div>-->

<!--    <h3>Input Element</h3>-->
<!--    <input v-model="sliderValue" />-->
  </div>
</template>

<script>
import RoundSlider from "vue-round-slider";

export default {
  name: "WineSlider",
  components: {
    RoundSlider,
  },

  props: ['product', 'countOfReviews', 'avgRating'],

  data() {
    return {
      sliderValue: null
    };
  },

  mounted() {

    this.sliderValue = this.avgRating;
  },

  methods: {
    tooltipFormat(e) {
      return "&#9733; " + e.value;
    },
    createTicks() {
      const $this = this.$refs.rslider.instance;
      var o = $this.options,
        tickInterval = o.step;
      for (var i = o.max; i <= o.min; i += tickInterval) {
        i = $this._round(i);
        var angle = $this._valueToAngle(i);
        var numberTag = $this._addSeperator(angle, "rs-custom");
        var number = numberTag.children();
        number.css({
          "margin-top": $this._border(true) / -2,
        });
        if (i % 1 === 0) number.addClass("rs-bold");
      }
      this.updateTickColor();
    },

    updateTickColor() {
      const $this = this.$refs.rslider.instance;
      const o = $this.options,
        tickInterval = o.step;
      const ticksCount = (o.value - o.max) / tickInterval + 1;
      const ticks = $this.control.find(".rs-custom");

      ticks.map((i, e) =>
        e.classList[i < ticksCount ? "add" : "remove"]("selected")
      );

    },

    save() {
      console.log('saved');




      $.ajax({
        method: 'POST',
        url: route('products.reviews.store', { productId: this.product.id }),
        data: {rating : this.sliderValue},
      }).then((review) => {
        this.customReviewForm = {};
        this.reviews.total++;
        this.reviews.data.unshift(review);

        $('.captcha-input').prev('img').trigger('click');
      }).catch((xhr) => {
        if (xhr.status === 422) {
          this.$notify('Toto víno už ste hodnotili', {type:'error'});
          this.errors.record(xhr.responseJSON.errors);
        } else {
          this.$notify('Hodnotenie bolo uložené', {type:'success'});
        }
      }).always(() => {
        this.postingReview = false;
      });


    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.box {
  padding: 10px;
  display: inline-block;
}
.rs-custom.rs-bar {
  z-index: 4;
}
.rs-custom .rs-border {
  border-color: #ababab;
  margin-left: 7px;
  width: 5px;
}
.rs-custom .rs-border.rs-bold {
  width: 14px;
}
.rs-custom.selected .rs-border {
  border-color: #ff9500;
}
.rs-control .rs-tooltip.rs-tooltip-text {
  top: 30px;
  font-size: 18px;
}
</style>
