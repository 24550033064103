<template>

  <div :class="classname ? classname : 'product-item'" class="">


      <div class="card">
        <a class="card-img-top" :href="productUrl">
        <img :src="picture"
             class="card-img-top" :alt="product.name"/>
        </a>

        <div class="card-body">


          <div class="text-center justify-content-between mb-3">
            <h5 class="mb-0"><a :href="productUrl">{{ product.name }}</a></h5>

            <h5 class="text-dark mb-0">
              <span class="product-prev-price" v-if="product.has_percentage_discount">{{
                  product.discount_percent
                }}</span>
              <span class="product-price" v-html="product.formatted_price"></span>
              <span class="product-price" style="font-size: 12px;color:#333;" v-if="locale=='hu'"
                    v-html="'('+product.hu_price+')'"></span>

              <span class="sold-out-tag position-top-right" v-if="!product.in_stock">{{ $trans('outOfStock') }}</span>
            </h5>
          </div>

          <div class="row">
            <a class="button btn-primary btn-block radius-10 button-3d color-button-default text-center solid"
               :class="{ 'btn-loading': cartAddLoading }"
               tabindex="0"
               @click="addToBasket"
               :disabled="product.is_out_of_stock"
               v-if="noOptions || product.is_out_of_stock">{{ $trans('buy') }}
            </a>

            <a class="button btn-primary btn-block radius-10 button-3d color-button-default solid"
               :class="{ 'btn-loading': cartAddLoading }"
               tabindex="0"
               @click="addToBasket"
               :href="productUrl"
               v-else>
              {{ $trans('showOptions') }}
            </a>
          </div>


        </div>
      </div>
  </div>


</template>

<script>
import ProductRating from './ProductRating.vue';
import ProductCardMixin from '../mixins/ProductCardMixin';

export default {

  components: {ProductRating},

  computed: {

    locale() {
      return window.App.locale;
    }

  },

  mixins: [
    ProductCardMixin,
  ],

  props: ['product', 'classname'],
};
</script>

<style>
.float-left {
  float: left;
}

.la-heart {
  color: #9e9e9e;
}

.la-heart.las {
  color: red;
}

</style>
