import store from '../store';

export default {
    data() {
        return {
            orderIsLoading: false,
            shippingMethodTitle: null,
            couponIsLoading: false,
            couponCode: null,
            couponError: null,
        };
    },

    computed: {
        basket() {
            return store.state.basket;
        },

        basketIsEmpty() {
            return store.basketIsEmpty();
        },

        basketIsNotEmpty() {
            return ! store.basketIsEmpty();
        },

        hasShipping() {
            return store.hasShipping();
        },

        defaultShipping() {
            return Object.keys(store.state.basket.shippingMethodOptions)[0];
        },

        hasCoupon() {
            return store.state.basket.coupon.code !== undefined;
        },
    },

    methods: {
        activateCoupon() {
            if (! this.couponCode) {
                return;
            }

            this.orderIsLoading = true;
            this.couponIsLoading = true;

            $.ajax({
                method: 'POST',
                url: route('cart.coupon.store', { coupon: this.couponCode }),
            }).then((cart) => {
                this.couponCode = null;
	            this.couponError = "Kupón bol aplikovaný";
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.couponError = xhr.responseJSON.message;
            }).always(() => {
                this.orderIsLoading = false;
                this.couponIsLoading = false;
            });
        },

        clearCoupon() {
            this.orderIsLoading = true;

            $.ajax({
                method: 'DELETE',
                url: route('cart.coupon.destroy'),
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.orderIsLoading = false;
            });
        },

        updateShipping(shippingMethodName) {

            if (! shippingMethodName) {
                return;
            }

            this.orderIsLoading = true;


            this.changeShippingMethod(shippingMethodName);

            $.ajax({
                method: 'POST',
                url: route('cart.shipping_method.store', { shipping_method: shippingMethodName }),
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.orderIsLoading = false;
            });
        },
        updatePayment(paymentMethodName) {
            if (! paymentMethodName) {
                return;
            }
            this.orderIsLoading = true;
            this.changePaymentMethod(paymentMethodName);

            $.ajax({
                method: 'POST',
                url: route('cart.payment_method.store', { payment_method: paymentMethodName }),
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.orderIsLoading = false;
            });
        },
    },
};
