<template>
  <section style="background-color: #eee;">
    <div class="container py-5">
      <div class="row">
    <ProductCard classname="col-md-12 col-lg-4 mb-4 mb-lg-0" :product="product"/>
      </div>
    </div>
  </section>
</template>

<script>
    import ProductCard from './../../ProductCard.vue';

    export default {
        components: { ProductCard },

        props: ['product'],
    };
</script>
